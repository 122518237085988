import Menu from 'Menu';
import React from 'react';
import SSiLogo from 'svg/SSiLogo';

export default
function Footer() {
  return (
    <footer>

      <div className="footer-hold">
        <div className="col logo">
          <SSiLogo />
        </div>
        <div className="col menu">
          <Menu htmlID='footer-menu' />
        </div>
      </div>

      <div className="copyright">
        <div className="container">
          <div className="copyright-hold">
            <div className="col">
              <p>© {new Date().getFullYear()} SaySomethingIn Cyf.</p>
            </div>
            <div className="col">
              <p>Glaslyn, Ffordd y Parc, Bangor, Gwynedd LL57 4FE, WALES</p>
            </div>
            <div className="col">
              <ul id="legal-menu" className="menu">
                <li>
                  <a href="https://www.saysomethingin.com/en/privacy-policy/">
                    Privacy Policy
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>

  );
}
