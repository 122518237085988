/* eslint-disable max-len */
import Block from 'Block';
import PictureArea from 'PictureArea';
import TextArea from 'TextArea';
import Banner from './Banner';
import neuralNet from 'images/neuralnet.svg';
import help from 'images/help.svg';

export default
function Techniques() {
  return <>
    <Banner text="Accelerated Learning Techniques" />

    <Block className='txt--center'>

      <h4>To achieve the fastest language acquisition possible,
        we solve for two different challenges. </h4>

      <h4>We solve for the <strong>neurological change</strong> that is
        necessary for you to speak and understand Welsh, and we solve for
        the <strong>emotional support</strong>, understanding and growth that
        makes it possible for you to learn and then use your new language.
      </h4>

    </Block>

    <Block className="bg-color--grey">
      <h2 className="txt--center">Neurological growth</h2>
      <div className="grid-hold">
        <TextArea>
          <p>Everything we do is focused on creating new synapses and new neural circuits in your brain - you’ll recognise that this process is happening successfully when you start to have the experience of ‘Welsh words bubbling up in your brain’.</p>

          <p>We target three key types of neural circuits. First, we build memories for words and for the edges between words, using a range of proven memory techniques. No-one else focuses on the edges between words in the same way we do (two PhD students are currently studying our methodology at Cardiff University) - and this is the work which takes away the need for you to study any grammar. When we’ve built the necessary memories for words and the edges between them, you will naturally start to produce grammatically correct statements.</p>

          <p>The second type of neural circuit we build is to allow you to process spoken Welsh quickly enough to be able to engage in conversations with first language Welsh speakers. We give you listening input at double or triple speed which triggers a neuroplastic response that allows your brain to process spoken Welsh significantly more quickly.</p>

          <p>Finally, we create situations which require you to spend time communicating only in Welsh, without falling back on your English. This triggers a precise neurological change which turns the memories you have already acquired (which are often referred to as ‘passive’) into words that you can access and use quickly (often referred to as ‘active’).</p>

          <p>When you have built a core set of memories for words and the edges between them, and have adapted to faster processing for spoken Welsh, and have activated your word and edges memories, you will be capable of taking part in Welsh conversations. At that point in your learning journey, the more conversations you take part in, the easier they will become. </p>
        </TextArea>
        <PictureArea style={{ color: '#b21c1f' }} src={neuralNet} />
      </div>
    </Block>

    <Block className="">
      <h2 className="txt--center">Emotional support</h2>
      <div className="grid-hold">
        <TextArea>
          <p>Accelerated language acquisition (like any accelerated learning) is emotionally challenging.</p>

          <p>You need to be comfortable making ‘mistakes’, and for many people that is complicated and difficult.</p>

          <p>Our coaches are experienced in helping you understand why ‘mistakes’ are actually the neurological moment of learning. They can help lead you to the point where you laugh at or celebrate mistakes, and that means your capacity for learning in all elements of your life becomes significantly stronger. Many of our clients report increased confidence in other areas of their life, as well as in their use of Welsh.</p>

          <p>You may also have a range of other emotional challenges that hamper your learning. You may have self-doubt remaining from experiences at school, you may have self-worth issues from unhappy previous experiences in your life, and you may suffer from the common modern problems of depression, a lack of belonging, loneliness, and low levels of purpose.</p>

          <p>We know it’s not ordinary to read about these issues in the context of learning Welsh.</p>

          <p><strong>We’re not an ordinary team.</strong></p>

          <p>When these issues aren’t addressed, people learn less successfully. </p>

          <p>Imagine two people in a 50 metre sprint. But one of them is carrying a 50kg backpack. You know who’s going to win.</p>

          <p>If you’re carrying a 50kg backpack into your efforts to learn Welsh, our coaches are trained to notice that, and to help you take it off and put it down. And that leads to better results for you, which is why we work that way.</p>

          <p>The combination of highly focused neurological work with experienced emotional support and inspiration leads consistently to very high levels of success.</p>

        </TextArea>
        <PictureArea src={help} />
      </div>
    </Block>
  </>;
}