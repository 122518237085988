/* eslint-disable max-len */
import Block from 'Block';
import { Link } from 'react-router-dom';
import TextArea from 'TextArea';
import Banner from './Banner';

export default
function Partners() {
  const PARTNERS = [
    {
      name: 'S4C',
      logo: 's4c.png',
      link: 'https://www.s4c.cymru',
    },
    {
      name: 'The National Centre for Learning Welsh',
      logo: 'canolfan.svg',
      link: 'https://learnwelsh.cymru',
    },
    {
      name: <>Senedd Cymru<br />Welsh Parliament</>,
      logo: 'senedd.svg',
      link: 'https://senedd.wales',
    },
    {
      name: 'Nant Gwrtheyrn',
      logo: 'nant.png',
      link: 'https://nantgwrtheyrn.org',
    },
    {
      name: 'Cardiff University',
      logo: 'cardiff.png',
      link: 'https://www.cardiff.ac.uk',
    },
    {
      name: 'Cardiff and Vale College',
      logo: 'cavc.png',
      link: 'https://cavc.ac.uk'
    },
  ];
  
  return <>
    <Banner text="Partner Organisations" />

    <Block className='txt--center'>
      <TextArea>
        <p>Our partner organisations commit to 5 days accelerated coaching each
          year, in return for a significant discount on those 5 days and on any
          other days they purchase.</p>
        <p>If you would like to contact us to discuss becoming a partner
          organisation, please
          email <a href="mailto:partners@saysomethingin.com">
          partners@saysomethingin.com</a> or
          visit <Link to='/contact'>our ‘Contact Us’ page</Link>.</p>
      </TextArea>
    </Block>

    <Block className='txt--center bg-color--grey'>
      <h2>Our current partners</h2>
      <div className="partners">
        {PARTNERS.map((partner,idx) => (
          <a href={partner.link} className="partner" key={idx}>
            <div className="logo">
              <img src={`/images/logos/${partner.logo}`} />
            </div>
            <div className="name">{partner.name}</div>
          </a>
        ))}
      </div>
    </Block>
  </>;
}