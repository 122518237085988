import Home from 'pages/Home';
import Pricing from 'pages/Pricing';
import Techniques from 'pages/Techniques';
import Partners from 'pages/Partners';
import Contact from 'pages/Contact';

export default [
  { path: '/',           component: Home,       name: 'Home'                  },
  { path: '/pricing',    component: Pricing,    name: 'Pricing'               },
  { path: '/techniques', component: Techniques, name: 'Learning Techniques'   },
  { path: '/partners',   component: Partners,   name: 'Partner Organisations' },
  { path: '/contact',    component: Contact,    name: 'Contact Us'            },
];