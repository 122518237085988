import { sendForm } from 'funcs';
import { useState } from 'react';

export default
function ContactForm() {
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [subject, setSubject] = useState();
  const [message, setMessage] = useState();
  const [sending, setSending] = useState(false);

  async function onSubmit() {
    setSending(true);
    sendForm({
      subject: `CoE Contact: ${subject}`,
      fields: { name, email, subject, message }
    }).then(data => {
      console.log('data', data);
    }).finally(() => setSending(false));
  }

  return (
    <fieldset className="form" disabled={sending}>
      <div className="col half">
        <label>Name</label>
        <input type="text" value={name}
          onChange={e => setName(e.target.value)} />
      </div>
      <div className="col half">
        <label>Email Address</label>
        <input type="email" value={email}
          onChange={e => setEmail(e.target.value)} />
      </div>
      <div className="col">
        <label>Subject</label>
        <input type="text" value={subject}
          onChange={e => setSubject(e.target.value)} />
      </div>
      <div className="col">
        <label>Message</label>
        <textarea value={message}
          onChange={e => setMessage(e.target.value)} />
      </div>
      <button disabled={sending} onClick={onSubmit}>
        <span className="text">Submit</span>
        <div className="spinner"></div>
      </button>
    </fieldset>
  );
}