import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import 'style/reso.css';
import 'style/extra.scss';
import 'style/spinner.scss';
import Header from 'Header';
import Footer from 'Footer';
import Pages from 'Pages';
import NotFound from 'NotFound';

export default
function COE() {
  return (
    <div id="page">
      <BrowserRouter>
        <Header />

        <div id="content" className="site-content">
          <div id="primary" className="content-area">
            <main id="main" className="site-main" role="main">

              <Routes>
                {Pages.map(page =>
                  <Route key={page.path} path={page.path}
                    element={<page.component />} />
                )}
                <Route path='*' element={<NotFound />}/>
              </Routes>

            </main>
          </div>
        </div>

        <Footer />
      </BrowserRouter>
    </div> /* #page */
  );
}

