import PropTypes from 'prop-types';

export default
function PictureArea({className, ...props}) {
  return (
    <div className={'textarea-content side-pic ' + className ?? ''}>
      <div>
        <img loading="lazy" {...props} />
      </div>
    </div>
  );
}
PictureArea.propTypes = {
  className: PropTypes.string
};
