import Menu from 'Menu';
import React, { useState } from 'react';
import SSiLogo from 'svg/SSiLogo';

export default
function Header() {
  const [hamburgerOpen, setHamburgerOpen] = useState(false);

  const menuClasses = 'simple-menu ' + (hamburgerOpen ? 'menu-active' : '');
  const buttonClasses = 'hamburger hamburger--collapse' +
    (hamburgerOpen ? ' is-active' : '');

  return (
    <header id="main-menu" className={menuClasses}>
      <div className="header-contain">
        <div className="logo-hold">
          <a href="https://www.saysomethingin.com/">
            <SSiLogo />
          </a>
        </div>

        <button type="button" className={buttonClasses} title="Mobile Menu"
          onClick={() => setHamburgerOpen(!hamburgerOpen)}>
          <span className="hamburger-box">
            <span className="hamburger-inner"></span>
          </span>
        </button>
        
        <nav>
          <div className="primary-menu">
            <Menu />
          </div>
        </nav>

      </div>
    </header>

  );
}
