/* eslint-disable max-len */
export default function SSiLogo() {
  return (
    <svg className="ssi-logo" viewBox="0 0 214 48.386">
      <defs>
        <clippath id="clip-path">
          <rect id="Rectangle_17" data-name="Rectangle 17" width="214" height="48.386" stroke="rgba(0,0,0,0)" strokeWidth="1"></rect>
        </clippath>
      </defs>
      <g id="Group_101" data-name="Group 101" clipPath="url(#clip-path)">
        <path id="Path_1399" data-name="Path 1399" d="M.9,46.005a11.235,11.235,0,0,0,4.882,1.213c2.022,0,3.091-.838,3.091-2.109,0-1.213-.925-1.906-3.264-2.744C2.369,41.239.26,39.447.26,36.616c0-3.322,2.773-5.864,7.367-5.864a11.924,11.924,0,0,1,4.968.982l-.982,3.553a9.268,9.268,0,0,0-4.073-.924c-1.907,0-2.831.867-2.831,1.877,0,1.242,1.1,1.791,3.611,2.744,3.438,1.271,5.055,3.063,5.055,5.807,0,3.264-2.513,6.067-7.857,6.067A12.7,12.7,0,0,1,0,49.645Z" transform="translate(0 -8.539)" stroke="rgba(0,0,0,0)" strokeWidth="1"></path>
        <path id="Path_1400" data-name="Path 1400" d="M33.543,49.158a21.874,21.874,0,0,0,.2,3.438H29.787l-.26-1.415h-.087A5.091,5.091,0,0,1,25.4,52.913a4.33,4.33,0,0,1-4.564-4.333c0-3.669,3.293-5.43,8.291-5.4v-.2c0-.75-.4-1.82-2.571-1.82a7.946,7.946,0,0,0-3.9,1.069l-.808-2.831a11.668,11.668,0,0,1,5.488-1.242c4.709,0,6.211,2.773,6.211,6.095Zm-4.275-3.235c-2.311-.029-4.1.52-4.1,2.224A1.577,1.577,0,0,0,26.9,49.822,2.4,2.4,0,0,0,29.181,48.2a3.148,3.148,0,0,0,.087-.75Z" transform="translate(-5.785 -10.594)" stroke="rgba(0,0,0,0)" strokeWidth="1"></path>
        <path id="Path_1401" data-name="Path 1401" d="M44.6,38.592l2.108,6.933c.231.838.52,1.878.693,2.629h.087c.2-.751.433-1.82.636-2.629l1.734-6.933h4.708l-3.293,9.3c-2.022,5.6-3.38,7.857-4.969,9.273A8.1,8.1,0,0,1,42.056,59.1l-.924-3.726a5.54,5.54,0,0,0,1.906-.751,5.05,5.05,0,0,0,1.82-1.762,1.145,1.145,0,0,0,.231-.636,1.6,1.6,0,0,0-.2-.722L39.716,38.592Z" transform="translate(-11.028 -10.716)" stroke="rgba(0,0,0,0)" strokeWidth="1"></path>
        <path id="Path_1402" data-name="Path 1402" d="M62.571,47.479a8.9,8.9,0,0,0,4.507,1.271c2.571,0,4.073-1.358,4.073-3.322,0-1.82-1.04-2.86-3.669-3.872C64.3,40.431,62.34,38.784,62.34,36.04c0-3.034,2.514-5.287,6.3-5.287a8.843,8.843,0,0,1,4.3.953l-.693,2.051a7.755,7.755,0,0,0-3.7-.925c-2.658,0-3.669,1.589-3.669,2.918,0,1.82,1.185,2.715,3.872,3.755,3.293,1.271,4.969,2.86,4.969,5.719,0,3-2.225,5.633-6.818,5.633a10.051,10.051,0,0,1-4.969-1.271Z" transform="translate(-17.198 -8.539)" stroke="rgba(0,0,0,0)" strokeWidth="1"></path>
        <path id="Path_1403" data-name="Path 1403" d="M94.671,45.545c0,5.17-3.582,7.424-6.962,7.424-3.784,0-6.7-2.773-6.7-7.193,0-4.68,3.063-7.425,6.934-7.425,4.015,0,6.731,2.918,6.731,7.194m-11.093.144c0,3.062,1.762,5.373,4.246,5.373,2.426,0,4.246-2.282,4.246-5.43,0-2.369-1.185-5.373-4.189-5.373s-4.3,2.773-4.3,5.431" transform="translate(-22.494 -10.649)" stroke="rgba(0,0,0,0)" strokeWidth="1"></path>
        <path id="Path_1404" data-name="Path 1404" d="M103.759,42.454c0-1.445-.029-2.629-.116-3.784h2.224l.116,2.253h.087a4.818,4.818,0,0,1,4.39-2.571,4.134,4.134,0,0,1,3.958,2.8h.057a5.774,5.774,0,0,1,1.56-1.82,4.772,4.772,0,0,1,3.091-.982c1.849,0,4.593,1.213,4.593,6.067v8.233h-2.484V44.737c0-2.687-.982-4.3-3.033-4.3a3.291,3.291,0,0,0-3,2.311,4.188,4.188,0,0,0-.2,1.271v8.637h-2.485V44.274c0-2.225-.982-3.842-2.917-3.842a3.474,3.474,0,0,0-3.149,2.542,3.519,3.519,0,0,0-.2,1.242v8.436h-2.484Z" transform="translate(-28.78 -10.649)" stroke="rgba(0,0,0,0)" strokeWidth="1"></path>
        <path id="Path_1405" data-name="Path 1405" d="M137.714,46.122c.058,3.438,2.253,4.853,4.8,4.853a9.206,9.206,0,0,0,3.871-.722l.433,1.82a11.228,11.228,0,0,1-4.651.9c-4.3,0-6.876-2.86-6.876-7.077s2.485-7.54,6.558-7.54c4.564,0,5.777,4.015,5.777,6.587a9.619,9.619,0,0,1-.086,1.185Zm7.453-1.82c.029-1.618-.664-4.131-3.524-4.131-2.571,0-3.7,2.369-3.9,4.131Z" transform="translate(-37.567 -10.649)" stroke="rgba(0,0,0,0)" strokeWidth="1"></path>
        <path id="Path_1406" data-name="Path 1406" d="M158.666,34.152V37.5h3.64v1.936h-3.64v7.54c0,1.734.491,2.716,1.907,2.716a4.716,4.716,0,0,0,1.473-.174l.116,1.907a6.123,6.123,0,0,1-2.253.376,3.541,3.541,0,0,1-2.745-1.1,5.182,5.182,0,0,1-.982-3.64V39.439h-2.167V37.5h2.167V34.932Z" transform="translate(-42.767 -9.483)" stroke="rgba(0,0,0,0)" strokeWidth="1"></path>
        <path id="Path_1407" data-name="Path 1407" d="M168.626,29.753h2.543v8.724h.059a4.7,4.7,0,0,1,1.819-1.791,5.23,5.23,0,0,1,2.6-.722c1.878,0,4.883,1.156,4.883,5.98v8.32h-2.542V42.232c0-2.254-.839-4.16-3.236-4.16a3.654,3.654,0,0,0-3.409,2.542,3.092,3.092,0,0,0-.173,1.213v8.435h-2.543Z" transform="translate(-46.825 -8.262)" stroke="rgba(0,0,0,0)" strokeWidth="1"></path>
        <path id="Path_1408" data-name="Path 1408" d="M192.751,32.742a1.574,1.574,0,0,1-3.149,0,1.549,1.549,0,0,1,1.589-1.589,1.509,1.509,0,0,1,1.559,1.589m-2.831,3.929h2.543V50.653H189.92Z" transform="translate(-52.649 -8.65)" stroke="rgba(0,0,0,0)" strokeWidth="1"></path>
        <path id="Path_1409" data-name="Path 1409" d="M198.554,42.454c0-1.445-.029-2.629-.116-3.784h2.253l.145,2.311h.058a5.141,5.141,0,0,1,4.622-2.629c1.935,0,4.94,1.156,4.94,5.951v8.349h-2.543v-8.06c0-2.253-.838-4.13-3.235-4.13a3.612,3.612,0,0,0-3.409,2.6,3.675,3.675,0,0,0-.173,1.184v8.407h-2.543Z" transform="translate(-55.103 -10.649)" stroke="rgba(0,0,0,0)" strokeWidth="1"></path>
        <path id="Path_1410" data-name="Path 1410" d="M231.752,50.629c0,3.206-.636,5.171-1.993,6.384a7.411,7.411,0,0,1-5.084,1.676,9.023,9.023,0,0,1-4.651-1.156l.636-1.936a7.874,7.874,0,0,0,4.1,1.1c2.6,0,4.507-1.357,4.507-4.881v-1.56h-.058a4.991,4.991,0,0,1-4.449,2.34c-3.467,0-5.951-2.946-5.951-6.817,0-4.738,3.091-7.425,6.3-7.425a4.651,4.651,0,0,1,4.362,2.427h.058l.116-2.109h2.224c-.058,1.011-.116,2.138-.116,3.843Zm-2.513-6.442a3.621,3.621,0,0,0-.144-1.156,3.663,3.663,0,0,0-3.553-2.686c-2.427,0-4.16,2.051-4.16,5.287,0,2.744,1.387,5.026,4.131,5.026a3.716,3.716,0,0,0,3.524-2.6,4.42,4.42,0,0,0,.2-1.358Z" transform="translate(-60.76 -10.649)" stroke="rgba(0,0,0,0)" strokeWidth="1"></path>
        <path id="Path_1411" data-name="Path 1411" d="M243.614,33.006a1.129,1.129,0,0,1-1.156,1.184,1.112,1.112,0,0,1-1.068-1.184,1.185,1.185,0,0,1,1.126-1.213,1.127,1.127,0,0,1,1.1,1.213m-1.791,3.958h1.358V50.83h-1.358Z" transform="translate(-67.03 -8.828)" stroke="rgba(0,0,0,0)" strokeWidth="1"></path>
        <path id="Path_1412" data-name="Path 1412" d="M249.253,42.152c0-1.271-.058-2.225-.116-3.322h1.271l.116,2.513h.058a5.2,5.2,0,0,1,4.767-2.831c1.328,0,4.824.665,4.824,5.864V52.7h-1.358v-8.2c0-2.514-.982-4.8-3.842-4.8a4.43,4.43,0,0,0-4.16,3.177,4.143,4.143,0,0,0-.2,1.242V52.7h-1.358Z" transform="translate(-69.181 -10.694)" stroke="rgba(0,0,0,0)" strokeWidth="1"></path>
        <path id="Path_1413" data-name="Path 1413" d="M282.126,1.687A3.184,3.184,0,0,1,285.3,4.862V18.746a3.184,3.184,0,0,1-3.175,3.175h-3.7l-6.672,7.168V21.921h-4.046a3.184,3.184,0,0,1-3.175-3.175V4.862a3.184,3.184,0,0,1,3.175-3.175Zm0-1.687H267.708a4.867,4.867,0,0,0-4.862,4.862V18.746a4.867,4.867,0,0,0,4.862,4.862h2.359v9.769l2.921-3.138,6.172-6.631h2.966a4.867,4.867,0,0,0,4.862-4.862V4.862A4.867,4.867,0,0,0,282.126,0" transform="translate(-72.988 0)" stroke="rgba(0,0,0,0)" strokeWidth="1"></path>
      </g>
    </svg>
  );
}
