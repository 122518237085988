import Block from 'Block';
import TextArea from 'TextArea';
import Banner from './Banner';
import ContactForm from './ContactForm';

export default
function Contact() {


  return <>
    <Banner text="Contact Us" />  

    <Block className='block__contact-details txt--center'>
      <div className="contact-wrapper">
        <div className="col">
          <img src="/images/map-pin.svg" alt="Location Icon" loading="lazy" />
          <p>
              Glaslyn, Ffordd y Parc,<br />
              Bangor, Gwynedd LL57 4FE<br />
              WALES
          </p>
        </div>
        <div className="col">
          <img src="/images/mail.svg" alt="Email Icon" loading="lazy" />
          <p><a href="mailto:admin@saysomethingin.com">
              admin@saysomethingin.com</a></p>
        </div>
      </div>
    </Block>

    <Block className="bg-color--grey">
      <TextArea>
        <ContactForm />
      </TextArea>
    </Block>
  </>;
}