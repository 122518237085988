/* eslint-disable max-len */
import Block from 'Block';
import { Link } from 'react-router-dom';
import TextArea from 'TextArea';
import Banner from './Banner';
import bubbles2 from 'images/bubbles2.png';
import bubbles3 from 'images/bubbles3.png';
import cardiff from 'images/cardiff.jpg';
import LocationForm from './LocationForm';

export default
function Pricing() {
  return <>
    <Banner text="Pricing" />

    <Block><TextArea>

      <p>You can choose a <strong>1</strong>, <strong>3</strong> or <strong>5
        day</strong> accelerated course.</p>

      <p>All courses are 1-on-1 with a trained high performance language
      acquisition coach. </p>

      <p>All courses include lifetime access to the SaySomethinginWelsh{' '}
        <a href="https://www.saysomethingin.com/en/automagic/">AutoMagic
        Language Coach</a>.</p>

      <p>Your results will vary depending on how much Welsh you’ve studied
        previously - <a href="#expectations">see below for outline
        expectations</a>.</p>

      <p>Our per day cost is <strong>£2000 (+VAT)</strong>. </p>

      <p>If you’re involved with <Link to='/partners'>one of our partner
        organisations</Link> you can contact them to find out about
        organisational discounts. </p>

      <p>You can also <Link to='/contact'>contact us</Link> to find out about
        S4C bursaries (they range from a 50% to a 100% discount but require you
        to be available to film for ‘Iaith ar Daith’). </p>

    </TextArea></Block>

    <Block className="bg-color--grey" id='expectations'>
      <h2 className="txt--center">Expectations</h2>
    </Block>

    <Block className="is-style-icon-right">
      <h3 className="txt--center">Starting from no previous Welsh</h3>
      <div className="grid-hold">
        <div className="media-content">
          <picture>
            <img style={{maxWidth: '400px' }} src={bubbles2} loading="lazy" />
          </picture>
        </div>
        <TextArea>
          <ul>
            <li>
              <h4>1 day</h4>
              <p>You will be capable of forming a range of useful sentences, and of using different structures to build your own sentences. You will recognise and be able to respond to some core structures when spoken by another person. You will have a new level of belief in your brain’s capacity to learn, and a new understanding of the most efficient and effective ways to give your brain the input it needs to learn successfully.</p>
            </li>
            <li>
              <h4>3 days</h4>
              <p>You will be capable of initial conversational interaction, and you will be able to express a solid range of needs, wishes and statements, as well as asking some of your own questions. You will be qualified and capable of taking part in S4C’s TV series ‘Iaith ar Daith’, and you will be finding Welsh words and phrases ‘bubbling up in your brain’ even when you’re not trying to think of them (this is the beginning of bilingualism). You will understand clearly the path you need to take to become a confident Welsh speaker.</p>
            </li>
            <li>
              <h4>5 days</h4>
              <p>You will be able to maintain and control conversational interaction in Welsh, asking people to slow down and repeat where necessary. You will mostly be able to understand people speaking Welsh to you when they use common words and phrases. You will be able to express a wide range of functional requirements, and you will be increasingly able to tell stories about other people and your own experiences. You will have a very high level of belief in your ability to become a confident and relaxed Welsh speaker, and you will be capable of spending an entire hour without using any English.</p>
            </li>
          </ul>
        </TextArea>
      </div>
    </Block>

    <Block className="is-style-icon-right bg-color--grey">
      <h3 className="txt--center">Starting from Intermediate</h3>
      <div className="grid-hold">
        <div className="media-content">
          <picture>
            <img style={{maxWidth: '400px' }} src={bubbles3} loading="lazy" />
          </picture>
        </div>
        <TextArea>
          <ul>
            <li>
              <h4>1 day</h4>
              <p>If your previous work mostly focused on reading and writing, your first day with us will see a sudden blossoming of your confidence with spoken Welsh. You will discover that you can make use of all your previous work to build and expand on the core structures that you will be practising with us, and by the end of the day you will be confident in your ability to spend at least an hour communicating in Welsh without using any English. If you already had an intermediate level of confidence with your spoken Welsh, the process will seem even faster and more exciting.</p>
            </li>
            <li>
              <h4>3 days</h4>
              <p>You will be deeply confident in your ability to stay in Welsh for extended periods of time, and to communicate everything necessary for personal and for work interactions. You will be increasingly conscious of the difference between your Welsh and your English, which doesn’t happen until you have a strong core of bilingualism, and we will help you navigate the occasional sense of frustration you will have that Welsh doesn’t yet feel as easy for you as English. You will know that you are a functional Welsh speaker, and you’ll be glad of opportunities to use your Welsh with other Welsh speakers.</p>
            </li>
            <li>
              <h4>5 days</h4>
              <p>You will have normalised communicating in Welsh for extended periods of time, to the point where it will feel slightly strange to use English with people who have been speaking Welsh with you. You will know that you are capable of communicating successfully in Welsh whenever you have the opportunity, and you’ll be able to see clearly that the nature of your Welsh - how easy it feels, how expressive you are, how natural you sound - will depend on how much room you are able to make for it in your life. You will be more than ready to form social circles through the medium of Welsh and to work through the medium of Welsh if the opportunity arises, and the more you do so, the easier and more natural it will feel.</p>
            </li>
          </ul>
        </TextArea>
      </div>
    </Block>

    <Block className="is-style-icon-left">
      <h2 className="txt--center">Locations</h2>
      <div className="grid-hold">
        <div className="media-content">
          <picture>
            <img src={cardiff} loading="lazy" />
          </picture>
        </div>
        <TextArea>
          <p>You can request a coach to come to your home address (business class travel and accommodation will be charged as extra).</p>

          <p>You can work at one of our Cardiff locations:</p>

          <ul>
            <li>If you represent Wales in sports: our Leckwith campus</li>
            <li>If you represent Wales in arts/literature/business: our city centre campus</li>
            <li>If you represent Wales in politics: our location in Cardiff University</li>
          </ul>

          <p>If you work from one of our Cardiff locations, we’ll ask you to spend some time in the middle of the day (you’ll be glad of the break!) speaking briefly to some of our students, in order to help inspire them with a positive attitude towards the Welsh language. </p>

          <p>If you’ve chosen a 3 or 5 day course, you also have the option to work from the National Language Centre at Nant Gwrtheyrn in Gwynedd (accommodation and all meals at £100 per night). </p>

        </TextArea>
      </div>
    </Block>

    <Block>
      <LocationForm />
    </Block>

  </>;
}