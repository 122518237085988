
const BASE_URL = `${process.env.REACT_APP_API_BASE_URL}/api/2/`;

export
async function backendCall(method, endpoint, params) {
  const url = `${BASE_URL}${endpoint}`;

  let options = {
    method: method,
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
  };

  if (params)
    options['body'] = JSON.stringify(params);

  return await fetch(url, options)
    .then((response) => {
      if (!response.ok)
        throw response;
      return response;
    })
    .then(data => data.json());
}

export async function sendForm(params) {
  return backendCall('POST', '/contact', {
    contact: {
      subject: params.subject,
      fields: params.fields
    }
  }).catch(e => {
    // alert('error ' + e);
    console.error('error', e);
  });
}
