import Pages from 'Pages';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

export default
function Menu({htmlID}) {
  const currentPath = useLocation().pathname;

  return (
    <ul id={htmlID} className="menu">
      {Pages.map(page => (
        <li key={page.path} className={
          page.path == currentPath ? 'current-menu-item' : undefined
        } >
          <a href={page.path}>{page.name}</a>
        </li>
      ))}
    </ul>
  );
}
Menu.propTypes = {
  htmlID: PropTypes.string
};