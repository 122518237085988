import banner from 'images/banner.jpg';
import PropTypes from 'prop-types';
import SSiLogo from 'svg/SSiLogo';

export default
function Banner({text}) {
  const title = <>
    <span style={{whiteSpace: 'nowrap'}}>The <SSiLogo /></span> Centre
    of Excellence for Accelerated Welsh
  </>;
  return (
    <div className="block__banner block__banner--has-img">
      <div className="container">
        <div className="banner__content">
          <div className="col content ">
            <h1>
              {text ? <>
                <div className='topTitle'>{title}</div>
                {text}
              </> : title}
            </h1>
          </div>
          <div className="col media">
            <picture>
              <img src={banner} loading="lazy" />
            </picture>
          </div>
        </div>
      </div>
    </div>
  );
}
Banner.propTypes = {
  text: PropTypes.string,
};
