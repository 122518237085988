/* eslint-disable max-len */
import Block from 'Block';
import Banner from './Banner';
import Testi1 from 'images/testi1.jpg';
import TextArea from 'TextArea';

export default
function Home() {
  return <>
    <Banner />  

    <Block>
      <TextArea className='txt--center'>
        <h3>
          Powered by the SaySomethinginWelsh AutoMagic Language Coach System
        </h3>
        <h4>
          In partnership with{' '}
          <a href="https://www.s4c.cymru/en/entertainment/iaith-ar-daith/">
            the S4C TV series ‘Iaith ar Daith’<br />
          </a> <small>(graduates include Carol Vorderman, Ruth Jones, Scott
            Quinnell, Colin Jackson, Chris Coleman, Joanna Scanlan)</small>
        </h4>
      </TextArea>
      <div className="grid-hold">
        <TextArea>
          <p>
            If you’re proud to represent Wales - in sports, arts or politics -
            and you want to get your Welsh up to speed as quickly as possible -
            the Centre of Excellence for Accelerated Welsh has been designed
            specifically for you. 
          </p>
          <p>
            Our approach pushes you to your limits in a one-on-one setting -
            Josh Navidi called it as tough as a Gatland training session! - and
            that’s how we get the remarkable results that you can see showcased
            on ‘Iaith ar Daith’. It’s a unique approach, and will feel unlike
            anything you’ve experienced previously.
          </p>
          <p>
            Our coaches are also trained in the psychology of high performance
            in language acquisition - they will maintain a laserlike focus on
            your neurological and emotional journey over the course of your time
            with them - while you develop your Welsh skills, they’ll be becoming
            experts in your personal learning skillset. This allows them to
            adapt the work you do to make sure that you stay permanently just
            outside your comfort zone, which is the optimal place for
            accelerated learning.
          </p>
          <p>
            You’ll be given full lifetime access to the <strong>
            SaySomethinginWelsh AutoMagic Language Coach </strong> (
            <a href="https://automagic.saysomethingin.com/">
              which you can also work with independently here
            </a>).
          </p>

        </TextArea>

        <div className="video-area">
          <div className="embed-youtube iframe-cont video-responsive">
            <iframe width="560" height="315" src="https://www.youtube.com/embed/_PQBcAgTZTM?modestbranding=1" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
          </div>
        </div>

      </div>

    </Block>

    <Block className="bg-color--grey txt--center"><TextArea>

      <h3 className="txt--center">Testimonials</h3>

      <img src={Testi1} style={{
        maxWidth: '500px',
        margin: '30px 0',
      }} />

    </TextArea></Block>
  </>;
}