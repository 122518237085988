import { sendForm } from 'funcs';
import { useState } from 'react';

export default
function LocationForm() {
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [days, setDays] = useState(1);
  const [location, setLocation] = useState('Leckwith');
  const [dietary, setDietary] = useState('');
  const [home, setHome] = useState('');
  const [sending, setSending] = useState(false);

  function onSubmit() {
    setSending(true);
    sendForm({
      subject: `CoE Course request: ${3} days at ${location}`,
      fields: { name, email, days, location, dietary }
    }).then(data => {
      console.log('data', data);
    }).finally(() => setSending(false));
  }

  const cost = days * (location == 'Nant' ? 1100 : 1000);

  return (
    <fieldset disabled={sending} className="form">
      <div className="col half">
        <label>Name</label>
        <input type="text" value={name}
          onChange={e => setName(e.target.value)} />
      </div>
      <div className="col half">
        <label>Email Address</label>
        <input type="email" value={email}
          onChange={e => setEmail(e.target.value)} />
      </div>
      <div className="col half">
        <label>Number of days</label>
        <select value={days} onChange={e => {
          const val = e.target.value;
          setDays(val);
          if (val == 1 && location == 'Nant')
            setLocation('Leckwith');
        }}>
          <option value="1">1</option>
          <option value="3">3</option>
          <option value="5">5</option>
        </select>
      </div>
      <div className="col half">
        <label>Location</label>
        <select value={location} onChange={e => setLocation(e.target.value)}>
          <option value="Leckwith">Leckwith Campus</option>
          <option value="City">City Centre Campus</option>
          <option value="Uni">Cardiff University</option>
          {days > 1 ?
            <option value="Nant">Nant Gwrtheyrn</option> :
            <option value="Nant" disabled>
              Nant Gwrtheyrn (3 or 5 day course only)</option>
          }
          <option value="Home">Home address</option>
        </select>
      </div>
      {location == 'Nant' && <div className="col">
        <label>Dietary requirements</label>
        <input type="text" value={dietary}
          onChange={e => setDietary(e.target.value)} />
      </div>}
      {location == 'Home' && <div className="col">
        <label>Where is your home address?</label>
        <input type="text" value={home}
          onChange={e => setHome(e.target.value)} />
      </div>}
      <div className="col txt--center">
        <p>
          Price: <strong>£{cost}</strong> + VAT
          {location == 'Home' && ' and travel expenses'}
        </p>
      </div>
      <button disabled={sending} onClick={onSubmit}>
        <span className="text">Submit</span>
        <div className="spinner"></div>
      </button>
    </fieldset>
  );
}