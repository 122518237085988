import PropTypes from 'prop-types';

export default
function TextArea({className, children}) {
  return (
    <div className={'textarea-content ' + className ?? ''}>
      <div>
        {children}
      </div>
    </div>
  );
}
TextArea.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};
