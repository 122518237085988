import PropTypes from 'prop-types';

export default
function Block({children, className, ...props}) {
  return (
    <div className={'block__textarea ' + className ?? ''} {...props}>
      <div className="container">
        {children}
      </div>
    </div>
  );
}
Block.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};
